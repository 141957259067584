// extracted by mini-css-extract-plugin
export var container = "services-module--container--qe4ja";
export var imagesContainer = "services-module--imagesContainer--RLvgn";
export var marginBottom = "services-module--marginBottom--rRq8I";
export var buttonsContainer = "services-module--buttonsContainer--OS2cm";
export var button = "services-module--button--NGnJk";
export var excerptContainer = "services-module--excerptContainer--5QBcF";
export var excerpt = "services-module--excerpt--80FvC";
export var title = "services-module--title--zVEAV";
export var list = "services-module--list--Xmlnt";
export var dot = "services-module--dot--tx+SK";
export var subList = "services-module--subList--NeCao";
export var dash = "services-module--dash--Q3dbX";